/** @jsx jsx */
import { jsx } from "@emotion/core";

import LVP from "./LVP";
import HardwoodFloors from "./Hardwood";
import PageTitle from "../../components/PageTitle";
import BottomLogo from "../../components/BottomLogo";

import { RouteProps } from "../index";
import NoScroll from "../../components/NoScrollbar";

export default ({ isMobile }: RouteProps) => {
  return (
    <div css={{ height: "100%", width: "100%" }}>
      <PageTitle
        title="Quality Material"
        subtitle={"The right material for the right job"}
        isMobile={isMobile}
      />
      <NoScroll>
        <div
          css={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            height: "100%",
            alignItems: "center"
          }}
        >
          <LVP isMobile={isMobile} />
          <HardwoodFloors isMobile={isMobile} />
        </div>
      </NoScroll>
      <BottomLogo inverse />
    </div>
  );
};
