import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "./apollo/client";
import Routes from "./routes/index";
import ErrorBoundary from "./components/ErrorBoundary";
import { ThemeProvider } from "emotion-theming";
import theme from "./utils/theme";
import { GlobalStyles } from "./utils/GlobalStyles";
import "./App.css";
import {
  createMuiTheme,
  ThemeProvider as MUIThemeProvider
} from "@material-ui/core/styles";
// @ts-ignore
const muiTheme = createMuiTheme({
  palette: {
    primary: { main: theme.colors.primary },
    secondary: { main: theme.colors.secondary },
    common: { white: theme.colors.white }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: theme.colors.softWhite
      }
    },
    MuiButton: {
      root: {
        marginLeft: "auto",
        display: "flex",
        marginTop: 15,
        fontWeight: 700
      }
    },
    MuiInput: {
      underline: {
        borderBottomColor: "#FFF",
        color: "#fff",
        "&::before": {
          borderColor: theme.colors.white
        },
        "&:hover:not(.Mui-disabled):before": {
          borderColor: theme.colors.white
        }
      },
      input: {
        fontWeight: 300,
        color: theme.colors.white
      }
    }
  }
});

const App: React.FC = () => {
  useEffect(() => {
    window.addEventListener("touchmove", e => e.preventDefault());
  });
  return (
    <>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <ErrorBoundary>
            <MUIThemeProvider theme={muiTheme}>
              <Routes />
            </MUIThemeProvider>
          </ErrorBoundary>
        </ApolloProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
