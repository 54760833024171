export enum colors {
  primary = "#fdbe57",
  secondary = "#86251C",
  background = "#F5F5F5",
  red = "#AF240B",
  grey = "#7F7F7F",
  white = "#F2F2F2",
  softWhite = "#f9f9f9",
  muted = "#C7C7C7",
  black = "#343635"
}

export enum text {
  font = "aaux-next, sans-serif",
  small = "14px",
  medium = "16px",
  large = "22px"
}

export default {
  colors,
  text
};

export const opacity = (
  opacity: number,
  colorString: string
): string | null => {
  // @ts-ignore
  const color = colors[colorString];

  if (!opacity || !color) return null;
  if (/rgb/.test(color)) {
    return `${color.slice(0, -1)},${opacity})`;
  } else if (/#/.test(color)) {
    return `${color}${convertHexOpacity(opacity)}`;
  } else {
    return color;
  }
};

const convertHexOpacity = (opc: number) => {
  let alpha = Math.round(opc * 100) / 100.0;
  alpha = Math.round(alpha * 255);

  let hexString = alpha.toString(16);
  if (hexString.length % 2) {
    hexString = "0" + hexString;
  }
  return hexString;
};
