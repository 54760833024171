import React from "react";
import styled from "@emotion/styled";

interface OverlayProps {}

const Overlay = styled.div`
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #33333399;
  position: absolute;
  z-index: 1;
`;

const OverlayContainer: React.FC<OverlayProps> = ({ children }) => {
  return <Overlay>{children}</Overlay>;
};

export default OverlayContainer;
