import { InputValues } from "../../hooks/useInputChange";

export const formValdator = (input: InputValues) => {
  const { name, from, phone, message } = input;
  const fields: String[] = [];
  if (!name || name.length < 1) fields.push("name");
  if (!from || from.length < 1 || !validateEmail(from)) fields.push("from");
  if (phone && !validatePhone(phone)) fields.push("phone");
  if (!message || message.length < 1) fields.push("message");
  return fields;
};

export function validateEmail(email: String) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone: String) => {
  var re = /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s.]{0,1}[0-9]{3}[-\s.]{0,1}[0-9]{4}$/;
  return re.test(String(phone).toLowerCase());
};
