import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import useSize from "../../utils/useWIndowSize";

interface PageContainerProps {
  image?: any;
  noPadding?: boolean;
}

const Container = styled.div`
  margin: auto;
  height: 100%;
  background-size: cover;
  background-image: url(${p => {
    //@ts-ignore
    return p.image;
  }});
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 50px;
  ${p => {
    //@ts-ignore
    if (p.noPadding) return ``;
    //@ts-ignore
    return p.isMobile
      ? `
    height: calc(100% - 50px);
    padding-bottom: 50px;
    `
      : `
    height: calc(100% - 100px);
    padding-bottom: 100px;
    `;
  }}
`;

const PageContainer: FunctionComponent<PageContainerProps> = ({
  image,
  noPadding,
  children
}) => {
  const { isMobile } = useSize();
  return (
    // @ts-ignore
    <Container image={image} isMobile={isMobile} noPadding={noPadding}>
      {children}
    </Container>
  );
};

export default PageContainer;
