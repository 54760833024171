// @ts-ignore
const images = require.context("../../assets/image/gallery", true);
export interface SourceType {
  dirName: string;
  images: GalleryImage[];
}

export interface GalleryImage {
  full: string;
  thumb: string;
}

export default async (): Promise<SourceType[]> => {
  const folders = images.keys().reduce((acc, key) => {
    const folder = key.match(/\.\/([\w_]*)\/.*/)[1];
    if (acc.includes(folder)) return acc;
    return [...acc, folder];
  }, []);

  const galleries = folders.map(dirName => {
    const galleryImages = images.keys().reduce((acc, key) => {
      const thumb = key.match(/\/thumb\//);
      const folder = key.match(/\.\/([\w_]*)\/(.*)/)[1];
      if (folder !== dirName || !!thumb) return acc;
      const fileName = key.match(/\/([\w_]*.jpg)/)[1];

      const filThumb = images
        .keys()
        .filter(
          i =>
            !!i.match(dirName) &&
            !!i.match(`thumb/${fileName.replace(".jpg", "")}`)
        );

      if (!filThumb.length) {
        console.warn(`Thumbnail for ${fileName} not found in thumb directory`);
        return acc;
      }
      return [
        ...acc,
        {
          full: images(key),
          thumb: images(filThumb[0])
        }
      ];
    }, []);

    return { dirName, images: galleryImages };
  });

  return galleries;
};
