import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  SendEmailMutation,
  SendEmailMutationVariables
} from "./__generated__/SendEmailMutation";
import { SendEmailInput } from "../../../apolloGlobals";

export default () => {
  // prettier-ignore
  const [sendEmailMutation] = useMutation<SendEmailMutation,SendEmailMutationVariables>(gql`
    mutation SendEmailMutation($input: SendEmailInput!) {
      sendEmail(input: $input) {
        sent
      }
    }
  `);
  return (input: SendEmailInput) => sendEmailMutation({ variables: { input } });
};
