import QualityMaterial from "../QualityMaterial/QualityMaterial";
import Home from "../Home/Home";
import Contact from "../Contact/Contact";
import WhatWeDo from "../WhatWeDo/WhatWeDo";
import ExpertCraftsmanship from "../ExpertCraftsmanship/ExpertCraftsmanship";

// background images
import ContactBackgroundImage from "../../assets/image/pageBackgrounds/teak.jpeg";
import ExpterCraftsmanshipBackgroundImage from "../../assets/image/pageBackgrounds/decoFloor_800.png";
import HomeBackgroundImage from "../../assets/image/pageBackgrounds/driftwood_800.png";
import QualityBackgroundImage from "../../assets/image/pageBackgrounds/modernKitchen_800.png";
import WhatWeDoBackgroundImage from "../../assets/image/pageBackgrounds/driftwood_800.png";

export default [
  {
    route: "home",
    Component: Home,
    title: "Home",
    background: HomeBackgroundImage,
    overlay: false,
    description: `Commited to quality: Dufner's Flooring installs hardwood and laminate vinyl flooring in Minneapolis, St. Paul and surrounding Minnesota communities`
  },
  {
    route: "whatwedo",
    Component: WhatWeDo,
    title: "What We Do",
    background: WhatWeDoBackgroundImage,
    overlay: false,
    description: `Hardwood and laminate vinyl planking installation`
  },
  {
    route: "qualityMaterial",
    Component: QualityMaterial,
    title: "Quality Materials",
    background: QualityBackgroundImage,
    overlay: false,
    description: `These are some of the great products we like working with`
  },
  {
    route: "mastercraftsmanship",
    Component: ExpertCraftsmanship,
    title: "Master Craftsmanship",
    background: ExpterCraftsmanshipBackgroundImage,
    overlay: true,
    description: "Exaples of some of our past flooring installs"
  },
  {
    route: "contact",
    Component: Contact,
    title: "Contact Us",
    background: ContactBackgroundImage,
    overlay: false,
    description: "Send us a message for a free installation quote"
  }
];
