import React, { useState, useRef } from "react";

const Expandable = ({ hide, children }) => {
  const content = useRef(null);
  const [height, setHeight] = useState("0px");

  if (
    (hide && height !== "0px") ||
    (content &&
      content.current &&
      !hide &&
      height !== `${content.current.scrollHeight}px`)
  )
    setHeight(hide ? "0px" : `${content.current.scrollHeight}px`);

  return (
    <div
      style={{
        overflow: "hidden",
        maxHeight: `${height}`,
        transition: "max-height 0.6s ease"
      }}
      ref={content}
    >
      {children}
    </div>
  );
};

export default Expandable;
