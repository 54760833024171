/** @jsx jsx */
import { jsx } from "@emotion/core";

import Information from "./Information";
import PageTitle from "../../components/PageTitle";
import BottomLogo from "../../components/BottomLogo";
import NoScrollbar from "../../components/NoScrollbar";

interface InputProps {
  isMobile: boolean;
}

export default ({ isMobile }: InputProps) => {
  return (
    <div css={{ height: "100%", width: "100%" }}>
      <PageTitle
        title="Flooring Installation"
        subtitle={"Making dreams happen"}
        isMobile={isMobile}
      />
      <NoScrollbar>
        <div
          css={{
            display: "flex",
            justifyContent: "space-around",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Information isMobile={isMobile} />
        </div>
      </NoScrollbar>
      <BottomLogo inverse />
    </div>
  );
};
