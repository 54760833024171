/** @jsx jsx */
import { jsx } from "@emotion/core";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import useWindowSize from "../utils/useWIndowSize";
//@ts-ignore
import { AnimatedSwitch } from "react-router-transition";
import styled from "@emotion/styled";
// import PageNotFound from "./PageNotFound/PageNotFound";
import SideNavigation from "./Navigation/SideNav";
import theme from "../utils/theme";
import routes from "./Navigation/routes";
import { FunctionComponent } from "react";
import SiteHelmet, { PageHelmet } from "../components/Helmet";

import PageContainer from "../components/Layout/PageContainer";
import OverlayContainer from "../components/Overlay";

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${theme.colors.black};
`;
export interface RouteProps {
  isMobile: boolean;
}
export default () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 600;

  return (
    <div css={{ maxWidth: "100vw" }}>
      <SiteHelmet />
      <Router>
        <AppContainer>
          <div
            css={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              position: "absolute"
            }}
          >
            <div
              css={{
                width: "100%"
              }}
            >
              <AnimatedSwitch
                atEnter={{ opacity: 0, height: "100%" }}
                atLeave={{ opacity: 0, height: "100%" }}
                atActive={{ opacity: 1, height: "100%" }}
                className="switch-wrapper"
              >
                {routes.map(
                  ({
                    Component,
                    route,
                    title,
                    background,
                    overlay,
                    description
                  }: {
                    Component: FunctionComponent<RouteProps>;
                    route: string;
                    title: string;
                    background: any;
                    overlay: boolean;
                    description: string;
                  }) => (
                    <Route exact path={`/${route}`} key={route}>
                      <PageHelmet title={title} description={description} />
                      {overlay && <OverlayContainer />}
                      <PageContainer
                        image={background}
                        noPadding={route === "home"}
                        key={route}
                      >
                        <Component isMobile={isMobile} />
                      </PageContainer>
                    </Route>
                  )
                )}
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route exact path="/index.html">
                  <Redirect to="/home" />
                </Route>
                <Route>
                  <Redirect to="/home" />
                  {/* <PageNotFound /> */}
                </Route>
              </AnimatedSwitch>
            </div>
            <SideNavigation />
          </div>
        </AppContainer>
      </Router>
    </div>
  );
};
