import React from "react";
import useColor from "../hooks/useColor";
import { colors } from "../utils/theme";

export enum Icons {
  up = "keyboard_arrow_up",
  radioUnChecked = "radio_button_unchecked",
  radioChecked = "radio_button_checked",
  send = "send"
}

interface GlyphiconProps {
  icon: keyof typeof Icons;
  color?: keyof typeof colors;
  size?: number;
  margin?: string;
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
  onClick?: () => void;
}

const Glyphicon = ({
  icon,
  color,
  size,
  margin,
  top,
  right,
  left,
  bottom,
  onClick
}: GlyphiconProps) => {
  return (
    <i
      onClick={onClick}
      style={{
        fontFamily: "Material Icons",
        fontStyle: "unset",
        color: useColor(color || "primary"),
        fontSize: size,
        margin,
        top,
        right,
        left,
        bottom,
        position: top || right || left || bottom ? "absolute" : "unset"
      }}
    >
      {Icons[icon]}
    </i>
  );
};

Glyphicon.defaultProps = {
  size: 18
};

export default Glyphicon;
