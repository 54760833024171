/** @jsx jsx */
import { jsx, InterpolationWithTheme } from "@emotion/core";
import { FunctionComponent } from "react";

interface ContainerProps {
  centerComponent?: Boolean;
  css?: InterpolationWithTheme<any>;
}

export const Container: FunctionComponent<ContainerProps> = ({
  children,
  centerComponent,
  css
}) => {
  let styles: InterpolationWithTheme<any> = {
    display: "flex",
    flexDirection: "column",
    //@ts-ignore
    ...css
  };

  return centerComponent ? (
    <CenterParent>
      <div css={styles}>{children}</div>
    </CenterParent>
  ) : (
    <div css={styles}>{children}</div>
  );
};

export const CenterParent: FunctionComponent<ContainerProps> = ({
  children
}) => {
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
      }}
    >
      {children}
    </div>
  );
};
