/** @jsx jsx */
import { jsx } from "@emotion/core";

import Lightbox from "./Lightbox";
import NoScrollbar from "../../components/NoScrollbar";
import PageTitle from "../../components/PageTitle";
import BottomLogo from "../../components/BottomLogo";

interface InputProps {
  isMobile: boolean;
}

export default ({ isMobile }: InputProps) => {
  return (
    <div css={{ height: "100%", width: "100%" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <PageTitle
          title="Master Craftsmanship"
          subtitle="Precision Installation"
          isMobile={isMobile}
        />
        <NoScrollbar
          height={isMobile ? "calc(100vh - 115px)" : "calc(100vh - 215px)"}
        >
          <div css={{ height: "100%" }}>
            <Lightbox isMobile={isMobile} />
          </div>
        </NoScrollbar>
      </div>
      <BottomLogo />
    </div>
  );
};
