import React from "react";
import { Global, css } from "@emotion/core";
import { text, colors } from "./theme";

export const GlobalStyles = () => (
  <Global
    styles={css`
      @import url("https://fonts.googleapis.com/css?family=Cantarell|Roboto+Slab&display=swap");
      * {
        font-family: ${text.font};
      }
      html {
        padding: 0;
        margin: 0;
        color: ${colors.black};
        background: ${colors.background};
      }
      body {
        padding: 0;
        margin: 0;
      }
      a {
        text-decoration: none;
      }
    `}
  />
);
