/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState } from "react";
import { Title, SubTitle, Text, Link } from "../../components/Type";
import Card, { CardImage, CardPadding } from "../../components/Layout/Card";
import lvpImage from "../../assets/image/card/bedroom.png";
import Expandable from "../../components/Expandable";

interface LVPProps {
  isMobile: boolean;
}
const LVP = ({ isMobile }: LVPProps) => {
  const [hide, setHide] = useState(true);

  return (
    <Card cardsPerRow={isMobile ? "one" : "two"} padding={0}>
      <CardImage url={lvpImage} alt={"LVP kitchen"} />
      <CardPadding>
        <Title shadow>Luxury Vinyl Planking</Title>
        <SubTitle color="muted" margin="0 0 15px 0">
          Consistant and water resistant
        </SubTitle>
        <Text color="muted">
          Luxury vinyl planking, commonly refered to as LVP, is a cost effective
          alternative to natural hardwood. LVP is an enginnered product that
          provides a conisistant look with little to no change in shape or size
          with ambient conditions.
        </Text>
        <Expandable hide={hide}>
          <Text color="muted" margin={"5px 0 0 0"}>
            Vinyl flooring is water, scratch and stain resistant and comes in a
            varity of styles to emulate almost any natural flooring choice.
          </Text>
          <Text color="muted" margin={"5px 0 0 0 "}>
            The most common issue people have with vinyl flooring is starting
            with an inferior product. The thickness of the wear layer, commonly
            reffered to in mils, makes a big difference in the amount of time
            before your floor starts to show signs of age. You want to look for
            something with a wear layer at least 16mils thick. We reccomend{" "}
            <Link to="https://coretecfloors.com/">CoreTEC floors</Link> for
            their durability, waterproof core and cork backing.
          </Text>
          <Text color="muted" margin={"5px 0 0 0"}>
            For more information a great resource is{" "}
            <Link to="https://floorcritics.com/">FloorCritics.com</Link>
          </Text>
        </Expandable>
        <div
          onClick={() => setHide(!hide)}
          css={{
            cursor: "pointer",
            width: "100%",
            justifyContent: "flex-end",
            display: "flex"
          }}
        >
          <Text color="softWhite" bold>
            {!hide ? "...Hide" : "...Learn More"}
          </Text>
        </div>
        <div css={{ marginTop: 15 }}>
          <SubTitle>View samples from our favorite manufacturers</SubTitle>
          <ul>
            <li>
              <Link to={"https://coretecfloors.com"} blank>
                CoreTEC Floors
              </Link>
            </li>
            <li>
              <Link
                to={
                  "https://www.karndean.com/en/floors/easy-fit-ranges/korlok-by-karndean"
                }
              >
                Korlok by Karndean
              </Link>
            </li>
            <li>
              <Link
                to={
                  "https://www.armstrongflooring.com/residential/en-us/rigid-core.html"
                }
              >
                Armstrong Rigid Core
              </Link>
            </li>
            <li>
              <Link to={"https://shawbuilderflooringsf.com/"}>
                Shaw Flooring
              </Link>
            </li>
          </ul>
        </div>
      </CardPadding>
    </Card>
  );
};

export default LVP;
