import React from "react";
import Helmet from "react-helmet";

const SiteHelmet = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Dufner's Flooring & Home Restoration</title>
      <link rel="canonical" href="http://dufnersflooring.com" />
    </Helmet>
  );
};

export default SiteHelmet;

export const PageHelmet = ({
  title,
  description
}: {
  title: string;
  description: string;
}) => {
  return (
    <Helmet>
      <title>{`Dufner's Flooring: ${title}`} </title>
      <meta name="description" content={description}></meta>
    </Helmet>
  );
};
