/** @jsx jsx */
import { jsx } from "@emotion/core";

import React, { useRef, useEffect, useState } from "react";
import useWindowDimensions from "../utils/useWIndowSize";
interface NoScrollProps {
  height?: string | number;
}
const NoScroll: React.FC<NoScrollProps> = ({ children, height }) => {
  const childRef = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(17);
  const { isMobile } = useWindowDimensions();
  useEffect(() => {
    const newWidth =
      childRef.current &&
      childRef.current.offsetWidth - childRef.current.clientWidth + 2;

    if (scrollWidth !== newWidth) {
      setScrollWidth(newWidth);
    }
  }, [childRef]); // eslint-disable-line

  return (
    <div
      css={{
        overflow: "hidden",
        height: height || `calc(100% - ${isMobile ? 55 : 110}px)`,
        zIndex: 2
      }}
    >
      <div
        ref={childRef}
        css={{
          overflow: isMobile ? "scroll" : "auto",
          height: `100%`,
          width: "100%",
          zIndex: 2,
          paddingRight: scrollWidth,
          overflowY: "scroll",
          boxSizing: "content-box",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default NoScroll;
