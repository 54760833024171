/** @jsx jsx */
import { jsx } from "@emotion/core";

import ContactBox from "./ContactBox";

import { RouteProps } from "../index";

import BottomLogo from "../../components/BottomLogo";
import PageTitle from "../../components/PageTitle";

export default ({ isMobile }: RouteProps) => {
  return (
    <div css={{ width: "100%", height: "100%" }}>
      <PageTitle
        title="Contact Us"
        subtitle="send us a message"
        isMobile={isMobile}
      />
      <div
        css={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ContactBox isMobile={isMobile} />
      </div>
      <BottomLogo />
    </div>
  );
};
