/** @jsx jsx */
import { jsx } from "@emotion/core";

import { Container } from "../../components/Layout/Container";
import { colors } from "../../utils/theme";

const MessageSent = () => {
  return (
    <Container centerComponent>
      <h1 css={{ color: colors.primary }}> Success!</h1>
      <p css={{ color: colors.softWhite, fontWeight: 300 }}>
        Your message has been sent. If you need immediate assistance feel free
        to give us a call at{" "}
        <a
          href="tel:7632760960"
          css={{
            color: colors.primary,
            cursor: "pointer",
            ":hover": { color: colors.softWhite }
          }}
        >
          763-876-0962
        </a>
      </p>
    </Container>
  );
};

export default MessageSent;
