import React from "react";
import styled from "@emotion/styled";
//@ts-ignore
import Lottie from "react-lottie";

import animation from "../../assets/lottie/scaled/data.json";
import useSize from "../../utils/useWIndowSize";

const Overlay = styled.div`
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #33333399;
  flex-direction: column;
`;
const Link = styled.a`
  font-size: 30px;
  color: #ddd;
  position: absolute;
  bottom: 15px;
`;

export default () => {
  const size = useSize();
  const width = size.width > 800 ? 700 : size.width - 50;
  return (
    <Overlay>
      <Lottie
        width={width}
        resizeMode={"cover"}
        options={{
          resizeMode: "cover",
          animationData: animation,
          loop: false
        }}
      />

      <Link href="tel:763-276-0962">763-276-0962</Link>
    </Overlay>
  );
};
