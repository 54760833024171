/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum WEBSITE {
  DUFNERS_FLOORING = "DUFNERS_FLOORING",
  STARGAZER = "STARGAZER",
}

export interface SendEmailInput {
  from: string;
  name: string;
  phone?: string | null;
  message: string;
  website: WEBSITE;
  subject?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
