/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
// @ts-ignore
import Carousel, { Modal, ModalGateway } from "react-images";
import sourcesAsync, { SourceType } from "./sources";
import Gallery from "react-photo-gallery";

interface LightboxProps {
  isMobile: boolean;
}
interface ImageCardProps {
  isMobile: boolean;
  image: string;
  thumb: string;
  text: string;
  alt: string;
}

const CarouselContainer = ({ isMobile }: LightboxProps) => {
  const [modalGallery, setModalGallery] = useState();
  const [galleries, setGalleries] = useState<SourceType[]>([]);
  useEffect(() => {
    sourcesAsync().then(setGalleries);
  }, []);

  const imageGallery =
    galleries && galleries.filter(g => g.dirName === modalGallery)[0];
  return galleries ? (
    <div css={{ maxWidth: 1200 }}>
      <Gallery
        //@ts-ignore
        targetRowHeight={200}
        columns={isMobile ? 2 : 6}
        margin={10}
        //@ts-ignore
        onClick={(p, k) => {
              //@ts-ignore
          setModalGallery(galleries[k.index].dirName);
        }}
        photos={galleries.map(({ images }) => ({
          src: images[0].thumb,
          width: 3,
          height: 4
        }))}
      />
      <ModalGateway>
        {imageGallery ? (
          //@ts-ignore
          <Modal onClose={() => setModalGallery(null)}>
            <Carousel
              //@ts-ignore
              hideControlsWhenIdle={false}
              //@ts-ignore
              spinnerColor="#FFF"
              //@ts-ignore
              views={imageGallery.images.map(img => ({
                source: img.full,
                thumbnail: img.thumb,
                author: "",
                description: "",
                caption: ""
              }))}
              thumbnailPosition={"left"}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  ) : null;
};

export default CarouselContainer;
