import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";

import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import {
  InMemoryCache
  // IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";

const API_KEY = "ot21/AtzcJH&qGlD4k145d=0OFpCm0";

// const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

const withToken = setContext(async () => {
  return {
    headers: {
      authorization: API_KEY
    }
  };
});

const httpLink = createHttpLink({
  uri: "https://stargazer-gql.herokuapp.com/"
});

const cache = new InMemoryCache({
  // fragmentMatcher,
});

export default new ApolloClient({
  link: ApolloLink.from([withToken, httpLink]),
  cache
});
