import { useState } from "react";
import { TextFieldProps } from "@material-ui/core/TextField";

export interface InputValues {
  name?: string;
  from?: string;
  phone?: string;
  message?: string;
}

const DEFAULT_VALUES = {
  name: undefined,
  from: undefined,
  phone: undefined,
  message: ""
};
export default (): [InputValues, (e: TextFieldProps) => void, () => void] => {
  const [input, setInput] = useState<InputValues>(DEFAULT_VALUES);

  const handleInputChange = (e: TextFieldProps) =>
    setInput({
      ...input,
      //@ts-ignore
      [e.currentTarget.name]: e.currentTarget.value
    });

  const resetValues = () => {
    setInput(DEFAULT_VALUES);
  };
  return [input, handleInputChange, resetValues];
};
