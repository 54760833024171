import React from 'react';

export default class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    if (this.state.error) {
      return (
        <div className="error-boundary">
          <header>
            <div className="😥">😥</div> {/* eslint-disable-line */}
            <div className="message-box" style={{ margin: 0 }}>
              <h1 className="m-b-10">Whoops</h1>
              <p>{this.state.error.toString()}</p>
            </div>
          </header>
          <div className="info">
            <b>{this.state.error.toString()}</b>
            <br />
            <br />
            {this.state.info &&
              this.state.info.componentStack.split('\n').map((i, k) => {
                return <div key={i + k}>{i}</div>;
              })}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
