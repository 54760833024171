import React from "react";
import { Title, SubTitle, Text, Link } from "../../components/Type";
import Card, { CardImage, CardPadding } from "../../components/Layout/Card";
import styled from "@emotion/styled";
import theme from "../../utils/theme";
import squareLogo from "../../assets/image/logos/square.png";
import callNow from "../../assets/image/callnow.png";
import Transformation from "../../assets/image/card/kitchenthree.png";

const Strong = styled.span`
  font-weight: bold;
  color: ${theme.colors.white};
`;

interface LVPProps {
  isMobile: boolean;
}
const Information = ({ isMobile }: LVPProps) => {
  return (
    <Card cardsPerRow={"one"} padding={0}>
      <CardImage
        url={Transformation}
        alt={"LVP kitchen"}
        css={{ margin: "auto", marginBottom: 10 }}
      />
      <CardPadding>
        <Title shadow>Amazing, fine tuned flooring installs</Title>
        <SubTitle color="muted" margin="0 0 15px 0">
          Commited to quality
        </SubTitle>
        <div>
          <div>
            <Text color="muted">
              Dufner's flooring is a family owned business based in Corcoran
              Minnesota. We are experts flooring installers that have two things
              in mind at all times, <Strong>Customer statisfaction</Strong> and
              <Strong> precision installation.</Strong>
            </Text>
            <Text color="muted" margin={"5px 0 0 0 "}>
              The quickest way to get started is to call Dan Dufner directly at{" "}
              <Link to="tel:7632760962">763-276-0962</Link> otherwise you can
              send us a message on our <Link to="/contact">contact page.</Link>
            </Text>
          </div>
          <Row>
            {!isMobile && (
              <img
                width={250}
                height={150}
                src={squareLogo}
                alt="square logo"
              />
            )}
            {isMobile && (
              <IconLink href="tel:7632760962">
                <img width={75} height={75} src={callNow} alt="square logo" />
              </IconLink>
            )}
            <ContactBox
              //@ts-ignore
              isMobile={isMobile}
            >
              <Name>Dufner's Flooring & Home Restoration</Name>
              <Line>Dan Dufner</Line>
              <Line>
                <Link thin to="tex:7632760962">
                  763-276-0962
                </Link>
              </Line>
              <Line>9837 Garden Lane</Line>
              <Line>Corcoran, MN 55374</Line>
            </ContactBox>
          </Row>
        </div>
      </CardPadding>
    </Card>
  );
};

export default Information;

const IconLink = styled.a`
  margin: 10px;
`;
const Name = styled.h2`
  font-size: 16px;
  margin: 0;
`;
const Row = styled.div`
  margin-top: 15px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Line = styled.div`
  font-size: 14px;
  color: ${theme.colors.muted};
`;
const ContactBox = styled.div`
  flex-basis: ${p =>
    // @ts-ignore
    !p.isMobile ? "50%" : "100%"};
`;
