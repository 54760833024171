/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent } from "react";
import { colors } from "../../utils/theme";
enum rowTable {
  one = "90%",
  two = "40%",
  three = "29%",
  four = "20%"
}

interface TitleProps {
  color?: keyof typeof colors;
  backgroundColor?: keyof typeof colors;
  shadow?: boolean;
  padding?: number;
  elevation?: number;
  cardsPerRow?: keyof typeof rowTable;
  margin?: number | string;
}

const Card: FunctionComponent<TitleProps> = ({
  children,
  color,
  backgroundColor,
  padding,
  elevation,
  cardsPerRow,
  margin
}) => {
  return (
    <div
      css={{
        boxShadow: elevation
          ? `${elevation}px ${elevation}px ${elevation}px #333`
          : "2px 2px 2px #222",
        backgroundColor: backgroundColor || `${colors.black}EE`,
        color: color || colors.softWhite,
        padding: padding === 0 ? 0 : padding || 20,
        flexBasis: rowTable[cardsPerRow || "one"],
        maxWidth: 700,
        margin: margin === 0 ? 0 : margin || "0 0 15px 0",
        height: "fit-content",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {children}
    </div>
  );
};

export default Card;

export const CardImage = ({
  url,
  alt,
  css
}: {
  url: string;
  alt: string;
  css?: any;
}) => <img src={url} css={{ width: "100%", ...css }} alt={alt} />;

export const CardPadding: FunctionComponent<{ padding?: string | number }> = ({
  children,
  padding
}) => <div css={{ padding: padding || `5px 20px 20px 20px` }}>{children}</div>;
