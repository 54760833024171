/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent } from "react";
import { colors } from "../../utils/theme";
interface TitleProps {
  color?: keyof typeof colors;
  shadow?: boolean;
  margin?: string | number;
  fontSize?: string | number;
  right?: boolean;
}

const SubTitle: FunctionComponent<TitleProps> = ({
  children,
  color,
  shadow,
  margin,
  fontSize,
  right
}) => {
  const lineHeight = (() => {
    if (!fontSize) return "1.1rem";
    if (typeof fontSize === "number") return `${fontSize}px`;
    return fontSize;
  })();
  return (
    <h3
      css={{
        color: colors[color || "softWhite"],
        textShadow: shadow ? "3px 3px 3px #333" : "",
        margin: margin || 0,
        fontWeight: 500,
        fontSize: fontSize || "1.25em",
        textAlign: right ? "right" : "left",
        lineHeight,
        "@media only screen and (max-width: 600px)": {
          fontSize: "1em",
          lineHeight: "1em"
        }
      }}
    >
      {children}
    </h3>
  );
};

export default SubTitle;
