import React from "react";

import styled from "@emotion/styled";

import BannerLogo from "../assets/image/logos/banner.png";
import InverseLogo from "../assets/image/logos/bannerInverse.png";
import useSize from "../utils/useWIndowSize";

interface BottomLogoProps {
  inverse?: boolean;
}

const BottomLogo = ({ inverse }: BottomLogoProps) => {
  const { isMobile } = useSize();
  return (
    // @ts-ignore
    <Container height={isMobile ? 50 : 100}>
      <Image
        //@ts-ignore
        image={inverse ? InverseLogo : BannerLogo}
        width={isMobile ? 200 : 400}
      />
    </Container>
  );
};

export default BottomLogo;

const Container = styled.div`
  justify-content: center;
  display: flex;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  height: ${p =>
    // @ts-ignore
    p.height}px;
`;

const Image = styled.div`
  padding-right: 50px;
  width: ${p =>
    //@ts-ignore
    p.width}px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  background-image: url(${p => {
    //@ts-ignore
    return p.image;
  }});
`;
