/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { colors } from "../../utils/theme";
import Button from "@material-ui/core/Button";
import Send from "@material-ui/icons/Send";
import useSendEmail from "./graphql/useSendEmail";
import { WEBSITE } from "../../apolloGlobals";
import useInputChange from "../../hooks/useInputChange";
import { formValdator } from "./formValidator";
import MessageSent from "./MessageSent";

interface ContactBoxProps {
  isMobile: Boolean;
}

const ContactBox = ({ isMobile }: ContactBoxProps) => {
  const sendEmail = useSendEmail();
  const [input, handleInputChange, resetInput] = useInputChange();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<String[]>([]);
  const [sent, setSent] = useState(false);

  const send = (e: any) => {
    e.preventDefault();
    const formErrors = formValdator(input);
    if (formErrors.length > 0) {
      setErrors(formErrors);
      return;
    }
    setLoading(true);
    setErrors([]);

    sendEmail({
      from: "test@stargazerllc.com",
      message: "Testing",
      website: WEBSITE.DUFNERS_FLOORING,
      name: "Kenny",
      ...input
    })
      .then(res => {
        setLoading(false);
        setSent(true);
        console.log(res);
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  if (sent)
    return (
      <div
        css={{
          width: "100%",
          maxWidth: 400,
          minHeight: 400,
          height: 400,
          backgroundColor: `#201e1ef2`,
          marginRight: 10,
          marginLeft: 10,
          padding: 20,
          boxShadow: "3px 3px 3px #333"
        }}
      >
        <MessageSent />
      </div>
    );
  return (
    <div
      css={{
        width: "100%",
        maxWidth: 400,
        minHeight: 400,
        backgroundColor: `#201e1ef2`,
        marginRight: 10,
        marginLeft: 10,
        padding: 20,
        boxShadow: "3px 3px 3px #333",
        zIndex: 10
      }}
    >
      <h2 css={{ color: colors.primary, margin: "auto", marginBottom: 10 }}>
        Let's Connect
      </h2>
      <form autoComplete="off" onSubmit={loading ? () => null : send}>
        <TextField
          id="standard-basic"
          fullWidth
          placeholder={"Joe Smith"}
          title="name"
          label="Name"
          name="name"
          required
          //@ts-ignore
          error={errors.includes("name")}
          //@ts-ignore
          onChange={handleInputChange}
          value={input.name}
          style={{ marginBottom: 10 }}
        />
        <TextField
          error={errors.includes("from")}
          required
          id="email"
          name="from"
          placeholder="Joe@yahoo.com"
          label="E-Mail"
          type={"email"}
          value={input.from}
          fullWidth
          style={{ marginBottom: 10 }}
          //@ts-ignore
          onChange={handleInputChange}
        />
        <TextField
          error={errors.includes("phone")}
          value={input.phone}
          id="phone"
          name="phone"
          type="tel"
          placeholder="555-555-5555"
          label="Phone Number"
          // @ts-ignore
          onChange={handleInputChange}
          style={{ marginBottom: 10 }}
          fullWidth
          helperText={
            errors.includes("phone")
              ? "Phone number can not contain letters"
              : ""
          }
        />

        <TextField
          value={input.message}
          error={errors.includes("message")}
          required
          id="message"
          name="message"
          //@ts-ignore
          onChange={handleInputChange}
          label="Comments"
          placeholder="I would love for you to build me a beautiful floor . . ."
          style={{ marginBottom: 10 }}
          fullWidth
          multiline
          rows={6}
        />
        <div css={{ display: "flex", justifyContent: "flex-end" }}>
          <div css={{ display: "flex" }}>
            <Button
              color="secondary"
              size="medium"
              variant="contained"
              type="reset"
              style={{ marginRight: 10 }}
              onClick={() => {
                resetInput();
                setErrors([]);
              }}
            >
              Reset
            </Button>
            <Button
              endIcon={!loading && <Send />}
              color="primary"
              size="medium"
              variant="contained"
              type="submit"
            >
              {!loading ? "Send" : "Sending . . ."}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactBox;
