/** @jsx jsx */
import { jsx } from "@emotion/core";
import MaterialIcon from "../../components/MaterialIcon";
import routes from "./routes";
import { Link, withRouter, RouteProps } from "react-router-dom";
import { colors } from "../../utils/theme";

const SideNavigation = ({ location }: RouteProps) => {
  return (
    <div
      css={{
        width: 50,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: `${colors.primary}CC`,
        alignItems: "center",
        zIndex: 999,
        position: "absolute",
        right: 0
      }}
    >
      <Link to={"/home"}>
        <MaterialIcon size={40} icon={"up"} color={"white"} />
      </Link>
      {routes.map(({ route }) => (
        <Link to={route} key={route}>
          <MaterialIcon
            size={30}
            icon={
              location && location.pathname.match(route)
                ? "radioChecked"
                : "radioUnChecked"
            }
            color={
              location && location.pathname.match(route) ? "black" : "white"
            }
            margin="0 0 10px 0"
          />
        </Link>
      ))}
    </div>
  );
};

export default withRouter(SideNavigation);
