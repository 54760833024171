/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent } from "react";
import { colors } from "../../utils/theme";
interface TitleProps {
  color?: keyof typeof colors;
  hoverColor?: keyof typeof colors;
  shadow?: boolean;
  margin?: string | number;
  to: string;
  thin?: boolean;
  blank?: boolean;
}

const Link: FunctionComponent<TitleProps> = ({
  children,
  color,
  shadow,
  margin,
  to,
  hoverColor,
  thin,
  blank
}) => {
  return (
    <a
      css={{
        color: colors[color || "softWhite"],
        ":hover": {
          color: colors[hoverColor || "primary"]
        },
        textShadow: shadow ? "3px 3px 3px #333" : "",
        margin: margin || 0,
        fontWeight: thin ? 300 : 600,
        cursor: "pointer"
      }}
      target={blank && "_blank"}
      href={to}
    >
      {children}
    </a>
  );
};

export default Link;
