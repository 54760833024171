/** @jsx jsx */
import { jsx } from "@emotion/core";

import { useState } from "react";
import { Title, SubTitle, Text, Link } from "../../components/Type";
import Card, { CardImage, CardPadding } from "../../components/Layout/Card";
import lvpImage from "../../assets/image/card/bedroom_2.png";
import Expandable from "../../components/Expandable";

interface LVPProps {
  isMobile: boolean;
}
const LVP = ({ isMobile }: LVPProps) => {
  const [hide, setHide] = useState(true);

  return (
    <Card padding={0} cardsPerRow={isMobile ? "one" : "two"}>
      <CardImage url={lvpImage} alt={"LVP kitchen"} />
      <CardPadding>
        <Title shadow>Hardwood Flooring</Title>
        <SubTitle color={"muted"} margin="0 0 15px 0">
          The classic solution
        </SubTitle>
        <Text color="muted">
          Solid hardwood flooring is 100% natural and ranges between 1/2 and 3/4
          of an inch thick. Given the solid nature of this beautiful product it
          can be sanded and refinished multiple times. It is not uncommon to see
          a hardwood floor that has lasted a century in some homes and continues
          to look beautiful.
        </Text>
        <Expandable hide={hide}>
          <Text color="muted" margin={"5px 0 0 0 "}>
            Because this is a natural product it can be succeptable to changes
            in heat and humidity. This means that over the lifespan of the
            product it will have slignt variations in size and shape. Therefore
            it is not reccomended for high humidity areas.
          </Text>
          <SubTitle margin={"10px 0 0 0"}>Prefinished vs. unfinished</SubTitle>
          <Text color="muted" margin={"5px 0 0 0 "}>
            This is a common crossroads for most homeowners. When choosing a new
            floor homeowners are hit with dozens of decisions before they decide
            on a finish. At Dufners flooring we recommend going with a
            prefinished product simply because it is more cost and time
            effective. There no toxic fumes or irritating smells and the overall
            cost of the job is typically less than going with an unfinished
            hardwood.
          </Text>
          <Text color="muted" margin={"5px 0 0 0 "}>
            People often choose to go with an unfinished floor because of
            matching problems. If you already have an oak floor in your kitchen
            you want extended into your living room it can be hard to find a
            matching product that comes prefinished. If that is the case we can
            purchase the same species of hardwood, lay it down and resurface the
            entire floor to have the seamless look most homeowners prefer.
            Unfinished flooring will also have more flexibility when it comes to
            install options. Plank widths can be adjusted, stain colors can be
            different as well as the clear coat which can have a range of luster
            types from matte to high gloss.
          </Text>
        </Expandable>
        <div
          onClick={() => setHide(!hide)}
          css={{
            cursor: "pointer",
            width: "100%",
            justifyContent: "flex-end",
            display: "flex"
          }}
        >
          <Text color="softWhite" bold>
            {!hide ? "...Hide" : "...Learn More"}
          </Text>
        </div>
        <div css={{ marginTop: 15 }}>
          <SubTitle>View samples from our favorite manufacturers</SubTitle>
          <ul>
            <li>
              <Link to={"https://www.builddirect.com/Wood-Flooring"} blank>
                Build Direct: Wood Flooring
              </Link>
            </li>
            <li>
              <Link to={"https://www.hursthardwoods.com/"}>
                Hurst Hardwoods
              </Link>
            </li>
          </ul>
        </div>
      </CardPadding>
    </Card>
  );
};

export default LVP;
