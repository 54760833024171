/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Title, SubTitle } from "./Type";

interface PageTitleProps {
  title: string;
  subtitle: string;
  isMobile: boolean;
}
const PageTitle = ({ title, subtitle, isMobile }: PageTitleProps) => {
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "flex-end",
        height: isMobile ? 60 : 105,
        alignItems: "center",
        zIndex: 2
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          paddingRight: isMobile ? 5 : 20
        }}
      >
        <Title
          color="primary"
          shadow
          fontSize={isMobile ? 20 : 45}
          margin={"0"}
          right
        >
          {title.toUpperCase()}
        </Title>
        <SubTitle shadow fontSize={isMobile ? 13 : 28} right>
          {subtitle}
        </SubTitle>
      </div>
    </div>
  );
};

export default PageTitle;
