import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  const invisibleHeight = document.body.offsetHeight - height;
  const isMobile = width < 600;
  return {
    isMobile,
    invisibleHeight,
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      //@ts-ignore
    }

    window.addEventListener(
      "load",
      () => {
        const dim = getWindowDimensions();
        setWindowDimensions(dim);
      },
      false
    );
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
