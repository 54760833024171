/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FunctionComponent } from "react";
import { colors } from "../../utils/theme";
interface TitleProps {
  color?: keyof typeof colors;
  shadow?: boolean;
  bold?: boolean;
  margin?: string | number;
}

const Title: FunctionComponent<TitleProps> = ({
  children,
  color,
  shadow,
  margin,
  bold
}) => {
  return (
    <p
      css={{
        color: colors[color || "softWhite"],
        textShadow: shadow ? "3px 3px 3px #333" : "",
        margin: margin || 0,
        fontWeight: bold ? 600 : 300,
        "@media only screen and (max-width: 600px)": {
          fontSize: 14
        }
      }}
    >
      {children}
    </p>
  );
};

export default Title;
