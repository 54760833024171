import { useContext } from "react";
import { ThemeContext } from "@emotion/core";
import { colors } from "../utils/theme";

export interface UseColorProps {
  colors: typeof colors;
}

export default (color: keyof typeof colors) => {
  const { colors } = useContext(ThemeContext) as UseColorProps;
  
  if (colors[color]) {
    return colors[color];
  } else {
    if (process.env.DEVELOPMENT) {
      console.warn(
        `${color} is not a theme color! You've extended the palette`
      );
    }
    return color;
  }
};
